/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  width: 80px !important;
  height: 36px !important;
  margin-bottom: 0px !important;
}
input[type="text"] {
  -moz-appearance: textfield;

  margin-bottom: 0px !important;
}
input[type="itemText"] {
  -moz-appearance: textfield;
}
.date-align {
  -moz-appearance: textfield;
}
.div-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-align-right {
  display: flex !important;
  align-items: right !important;
  justify-content: right !important;
}

.td-compact-table-cell-padding {
  padding: 8px 16px 8px 8px !important;
  min-width: 125px !important;
  max-width: 125px !important;
  vertical-align: middle;
}
.td-compact-table-cell-icon {
  padding: 8px 16px 8px 8px !important;
  min-width: 185px !important;
  max-width: 185px !important;
  align-items: right !important;
}

.isTableLoading {
  background-color: er-black !important;
  color: er-white !important;
}

.header {
  width: 100%;
  background-color: #c9c7c4;
  color: black;
  text-align: center;
  padding-top: 5px;
}

.footer {
  position: fixed;
  z-index: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  margin-top: -40px;
  clear: both;
  background-color: #191919;
  color: white;
  text-align: center;
}

.component-main {
  overflow: auto;
  padding-bottom: 40px;
}

.thead {
  background-color: #d1d1d1 !important;
  color: black;
  border-radius: 0 !important;
}

.bg-color-tabs {
  background-color: #f0f0f0;
}
.padding-tabs {
  padding-top: 5px;
}
.bg-color {
  background-color: #f7f7f7;
}
.icon-size {
  width: 80px;
  height: 80px;
}

.export-button-icon-size {
  padding-right: 3.67rem !important;
  padding-left: 3rem !important;
}

.flex-parent {
  display: flex;
}
.jc-center {
  justify-content: center;
}
button.margin-right {
  margin-right: 20px;
}
.ag-row .ag-cell {
  display: flex;
  align-items: center;
}
.inner-border {
  outline: 2px solid #ccc;
  border: 1px solid #999;
}
.button {
  word-wrap: break-word;
}
.center {
  margin: auto;
  width: 45%;
  padding: 250px;
}

.dc {
  position: absolute;
  left: 0px;
  width: 200px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
}

.mt-6 {
  margin-top: 11rem;
}

.wrapper {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-wrapper {
  margin-bottom: 30px;
}

.refresh-button {
  width: 72%;
}

.button-size {
  width: 14%;
}

.mb-table {
  margin-bottom: 0.5rem !important;
}

.min-height {
  min-height: 0!important;
}

.eds-text-field__input {
  border-radius: 8px ;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  width: 100% !important;
  border: 1px groove darkgrey !important;
}

.eds-color-blue-40 {
  border-color: red !important;
}

.css-1jelnj0 {
  min-height: 66px !important;
}

.mt-25 {
  margin-top: 0.7rem !important;
}


.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.container-home {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}